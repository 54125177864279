import React from 'react';
import ProductList from './ProductList';

const Inventory = () => {

    return (
        <>
        <ProductList />
        </>
    )

}

export default Inventory